import React from "react";
import { config } from "../config";
import { styled } from "styled-components";
import { devices } from "../styling-config";
import { useViewport } from "../hooks/useViewport";
const ArticleTitle = styled.div`
  color: white;

  @media only screen and ${devices.xs} {
    font-size: 1.7rem;
    padding: 1rem;
    padding-top: 2rem;
    font-family: "ReplicaLLWeb-Bold";
    font-weight: normal;
  }
  @media only screen and ${devices.md} {
    font-size: 2.2rem;
    padding-right: 7rem;
    padding-left: 2.5rem;
  }
  @media only screen and ${devices.lg} {
    font-size: 2.2rem;
    padding-right: 7rem;
    padding-left: 6.5rem;
  }
  @media only screen and ${devices["2xl"]} {
    font-family: "ReplicaLLWeb-Bold";
    font-weight: normal;
    font-size: 2.9rem;
    padding: 2rem;
    padding-top: 2rem;
    padding-right: 7rem;
    padding-left: 6.5rem;
  }

  @media only screen and (max-height: 600px) {
    font-size: 1.2rem;
  }
`;

const ArticleSubTitle = styled.div<{ boldsubtitle?: string }>`
  font-family: ${(props) =>
    props.boldsubtitle ? "ReplicaLLWeb-Bold" : "ReplicaLLWeb-Regular"};
  color: white;
  font-size: 1.7rem;
  margin-bottom: 1rem;
  padding-top: 0;
  white-space: pre-wrap;
  padding: 1rem;
  @media only screen and ${devices.md} {
    font-size: 2.2rem;
    padding-left: 2.5rem;
    padding-right: 7rem;
  }
  @media only screen and ${devices.lg} {
    font-size: 2.2rem;
    padding-left: 6.5rem;
    padding-right: 7rem;
  }
  @media only screen and ${devices["2xl"]} {
    font-size: 2.9rem;
    padding-left: 6.5rem;
    padding-right: 7rem;
  }

  @media only screen and (max-height: 600px) {
    font-size: 1.2rem;
  }
`;

const Author = styled.div`
  color: white;
  margin: 1rem;
  margin-right: 3rem;

  @media only screen and ${devices.xs} {
    margin-left: 1rem;
  }
  @media only screen and ${devices.md} {
    margin-left: 2.5rem;
  }
  @media only screen and ${devices.lg} {
    font-size: 1.4rem;
    margin-left: 6.5rem;
    padding-right: 7rem;
  }
`;

const ImageContainer = styled.div<{ image?: string }>`
  background-image: ${(props) => props.image};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 0.5rem;
`;

const Divider = styled.div`
  position: absolute;
  top: 2rem;
  bottom: 2rem;
  left: 5.7rem;
  border-left: 2px solid white;
  box-sizing: border-box;
`;

export const NavImageBlock = ({ item }: any) => {
  const { width } = useViewport();
  const image =
    width > 768
      ? item.image_src
      : item.mobile_image_src
      ? item.mobile_image_src
      : item.image_src;
  return (
    <ImageContainer
      className="image"
      image={`url(${config.API_BASE_URL}${image})`}
    >
      {width > 1024 ? <Divider /> : null}
      <ArticleTitle>{item.title}</ArticleTitle>
      {item.subtitle && (
        <ArticleSubTitle boldsubtitle={item.bold_subtitle}>
          {item.subtitle}
        </ArticleSubTitle>
      )}
      <Author>{item.author}</Author>
    </ImageContainer>
  );
};
