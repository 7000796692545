import React from "react";
import { ValkhofLogo } from "../components/ValkhofLogo";
import { PublicationTitle } from "../components/PublicationTitle";
import { ArticleNavContainer } from "../components/ArticleNavContainer";
import { AnimatedBackground } from "../components/AnimatedBackground";
import { PublicationTag } from "../components/PublicationTag";

export const LongreadNav = ({
  range,
  setRange,
}: {
  range: any;
  setRange: any;
}) => {
  return (
    <div
      className="longread"
      style={{
        height: "100dvh",
        width: "100dvw",
      }}
    >
      <div className="longread-index">
        <ValkhofLogo color="rgb(90,90,90)" />
        <PublicationTitle color="rgb(174, 174, 174)" />
        <PublicationTag />
        <ArticleNavContainer
          range={range}
          setRange={setRange}
          startRange={range}
        />
        <AnimatedBackground />
      </div>
    </div>
  );
};
