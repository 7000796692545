import React from "react";

export const WorkProperties = (props: {
  title_year: string;
  techniques: string;
}) => {
  return (
    <div className="work-properties">
      <div className="title-year">{props.title_year}</div>
      <div className="technique">{props.techniques}</div>
    </div>
  );
};
