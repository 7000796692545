import React from "react";

export const NavTextBlock = ({ item }: any) => {
  return (
    <div
      className="text"
      style={{
        height: "100%",
        width: "100%",
        color: "white",
      }}
    >
      <div className="text">{item.text}</div>
      <div className="author">{item.author}</div>
      <div className="year">{item.year}</div>
    </div>
  );
};
