import styled from "styled-components";
import { devices } from "../../styling-config";

import { _Text } from "../Article";

const TextBlock = styled.div`
  font-family: "ReplicaLLWeb-Regular";
  font-size: 1.14rem;
  width: 85%;
  color: black;
  margin-left: 2.7rem;

  color: ${(props) => props.color};

  hr {
    width: 10%;
    border: 0;
    border-bottom: 2px solid white;
  }
  strong {
    font-family: "ReplicaLLWeb-Bold";
    font-weight: normal;
  }

  p:first-child {
    margin-top: 0;
    padding-top: 1rem;
  }
  h3 {
    font-family: "ReplicaLLWeb-Regular";
    font-weight: normal;
    color: ${(props) => props.color};
  }

  p {
    line-height: 1.3;
  }

  p.indented {
    margin-left: 1rem;
  }

  p a {
    color: rgb(174, 174, 174);
    text-decoration: none;
  }
  p a:hover {
    color: rgb(174, 174, 174);
    text-decoration: underline;
  }

  @media only screen and ${devices.xs} {
  }
  @media only screen and ${devices.lg} {
    margin: 0 auto;
    max-width: 700px;
  }
`;

export const Text = (props: _Text) => {
  const textColor =
    props.color === "black" || props.color === "none" ? "white" : "black";
  return (
    <TextBlock
      dangerouslySetInnerHTML={{ __html: props.text }}
      color={textColor}
    />
  );
};
