import React from "react";

import { useQuery } from "react-query";
import axios from "axios";
import { useParams } from "react-router-dom";

import "../components/Longread.css";
import { Article } from "../components/Article";
import { config } from "../config";
import { devices } from "../styling-config";

import { styled } from "styled-components";

const Nav = styled.nav`
  h3 {
    color: ${(props) => props.color};
  }
  border-right: 2px solid ${(props) => props.color};
  @media only screen and ${devices.xs} {
    z-index: 0;
  }
  @media only screen and ${devices.lg} {
    z-index: 10;
  }
`;

const fetchArticle =
  (title: string = "") =>
  async () => {
    const response = await axios.get(
      `${config.API_BASE_URL}api/articles/${title}/`,
      {
        headers: {
          "Accept-Language":
            window.localStorage.getItem("language") || "default",
        },
      }
    );
    return response.data;
  };

export const Longread = () => {
  const { id } = useParams();
  const { data } = useQuery(`article-${id}`, fetchArticle(id));
  const color = data?.background_color ? data?.background_color : "black";
  const lineColor = color === "black" ? "white" : "black";

  return (
    <div className={`longread-article color-${color} page-${id}`}>
      <Nav color={lineColor}>{data && <h3>{data.sidebar_title}</h3>}</Nav>

      {data && (
        <Article
          articleBlocks={data.article_blocks}
          prev={data.prev_article}
          next={data.next_article}
          color={color}
        />
      )}
    </div>
  );
};
