import React from "react";
import { _ExtendedImage } from "../Article";
import styled from "styled-components";
import { config } from "../../config";

const Container = styled.div`
  font-size: 1.7rem;
  color: black;
  padding: 1rem;
`;
const Image = styled.img`
  width: 100%;
  margin-bottom: 1rem;
`;

const Author = styled.div`
  margin-left: 2.7rem;
  margin-bottom: 1.7rem;
  font-family: "ReplicaLLWeb-Bold";
  font-weight: normal;
`;
const Title = styled.div`
  margin-left: 2.7rem;
  font-family: "ReplicaMonoLLWeb-Regular";
  margin-bottom: 1.7rem;
`;
const MediumYear = styled.div`
  margin-left: 2.7rem;
  font-size: 1.14rem;
`;

export const ExtendedImage = (props: _ExtendedImage) => {
  return (
    <>
      <Image src={`${config.API_BASE_URL}${props.image_src}`} alt="" />
      <Container>
        <Author>{props.author}</Author>
        <Title>{props.title}</Title>
        <MediumYear>{props.medium_year}</MediumYear>
      </Container>
    </>
  );
};
