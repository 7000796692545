import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { OpeningImage } from "./article-blocks/OpeningImage";
import { Text } from "./article-blocks/Text";
import { InlineImage } from "./article-blocks/InlineImage";
import { ExtendedImage } from "./article-blocks/ExtendedImage";
import { IconClose } from "./IconClose";
import { Logos } from "./article-blocks/Logos";
import { devices } from "../styling-config";

export type _OpeningImage = {
  image_src: string;
  type: "opening_image";
  author: string;
  title: string;
  subtitle?: string;
  caption?: string;
  black_text?: number;
};
export type _Text = {
  color: string;
  text: string;
  type: "text";
};
export type _InlineImage = {
  image_src: string;
  caption: string;
  limit_width: number;
  type: "inline_image";
};
export type _ExtendedImage = {
  image_src: string;
  type: "extended_image";
  author: string;
  title: string;
  medium_year: string;
};
type Logo = {
  image_src: string;
  alt: string;
  hyperlink: string;
};

export type _Logos = {
  type: "logos";
  logoImages: Logo[];
};

const Label = styled.div`
  font-family: "ReplicaMonoLLWeb-Regular";
  font-size: 0.83rem;
  color: rgb(174, 174, 174);
  margin-left: 2.7rem;
  a {
    color: rgb(174, 174, 174);
    text-decoration: none;
  }
`;

const SiblingTitle = styled.div`
  font-family: "ReplicaLLWeb-Regular";
  font-size: 0.9rem;
  letter-spacing: 0.02rem;
  margin-left: 2.7rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  box-sizing: border-box;

  a {
    color: ${(props) => (props.color === "black" ? "white" : "black")};
    text-decoration: none;
  }
`;

const SiblingNav = styled.div`
  margin-top: 2rem;
  @media only screen and ${devices.lg} {
    margin: 0 auto;
    max-width: 700px;
  }
`;

type NextArticle = { name: string; title: string; subtitle?: string };
type PrevArticle = { name: string; title: string; subtitle?: string };

export type ArticleBlock =
  | _OpeningImage
  | _ExtendedImage
  | _InlineImage
  | _Text
  | _Logos;

const ArticleContainer = styled.article`
  /* padding-top: 6.5dvh; */
  &:last-child {
    padding-bottom: 3rem;
  }
`;

// create types for prevDict and nextDict
type Dict = {
  default: string;
  en: string;
  de: string;
};

const prevDict: { [key: string]: string } = {
  default: "Vorig artikel",
  en: "Previous article",
  de: "Vorheriger Artikel",
};

const nextDict: { [key: string]: string } = {
  default: "Volgend artikel",
  en: "Next article",
  de: "Nächster Artikel",
};

export const Article = ({
  articleBlocks,
  color,
  prev,
  next,
}: {
  articleBlocks: ArticleBlock[];
  color: string;
  prev: PrevArticle;
  next: NextArticle;
}) => {
  const [language, setLanguage] = useState("default");
  useEffect(() => {
    setLanguage(window.localStorage.getItem("language") || "default");
  });

  return (
    <ArticleContainer>
      <NavLink to="/index">
        <IconClose color="rgba(205,205,205,.5)" />
      </NavLink>
      {articleBlocks &&
        articleBlocks.map((block: ArticleBlock) => {
          switch (block.type) {
            case "opening_image":
              return <OpeningImage {...block} />;
            case "text":
              return <Text {...block} color={color} />;
            case "inline_image":
              return <InlineImage {...block} />;
            case "extended_image":
              return <ExtendedImage {...block} />;
            case "logos":
              return <Logos {...block} />;
            default:
              return "";
          }
        })}
      <SiblingNav>
        {next.name ? (
          <>
            <Label>
              <NavLink to={`/articles/${next.name}/`}>
                &gt; {nextDict[language]}
              </NavLink>
            </Label>
            <SiblingTitle color={color}>
              <NavLink to={`/articles/${next.name}/`}>
                {next.title}
                {next.subtitle
                  ? ", " +
                    next.subtitle
                      .split("\n")
                      .filter((a) => a.length > 1)
                      .join(", ")
                  : ""}
              </NavLink>
            </SiblingTitle>
          </>
        ) : (
          ""
        )}
        {prev.name ? (
          <>
            <Label>
              <NavLink to={`/articles/${prev.name}/`}>
                &lt; {prevDict[language]}
              </NavLink>
            </Label>
            <SiblingTitle color={color}>
              <NavLink to={`/articles/${prev.name}/`}>
                {prev.title}
                {prev.subtitle
                  ? ", " +
                    prev.subtitle
                      .split("\n")
                      .filter((a) => a.length > 1)
                      .join(", ")
                  : ""}
              </NavLink>
            </SiblingTitle>
          </>
        ) : (
          ""
        )}
      </SiblingNav>
    </ArticleContainer>
  );
};
