import React from "react";
import { styled } from "styled-components";
import { devices } from "../styling-config";
const Question = styled.div`
  color: white;
  font-size: 1rem;
  padding: 1rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
`;

const Title = styled.div`
  font-family: "ReplicaMonoLLWeb-Regular";
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.4;
  @media only screen and ${devices.xs} {
    margin-top: 0.2rem;
  }
  @media only screen and ${devices.lg} {
    margin-top: 1.5rem;
  }
  @media only screen and (max-height: 600px) {
    font-size: 0.8rem;
  }
`;

const SubTitle = styled.div`
  font-family: "ReplicaLLWeb-Regular";
  font-weight: normal;
  font-size: 0.9rem;
  @media only screen and (max-height: 600px) {
    font-size: 0.6rem;
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CenteredInner = styled.div`
  @media only screen and ${devices.xs} {
    width: 100%;
  }
  @media only screen and ${devices.lg} {
    width: 50%;
  }
`;

export const NavQuestionBlock = ({ item }: any) => {
  return (
    <Question>
      <Centered>
        <CenteredInner>
          <Title>{item.title}</Title>
          <SubTitle>{item.subtitle}</SubTitle>
        </CenteredInner>
      </Centered>
    </Question>
  );
};
