import React from "react";
import { NavLink } from "react-router-dom";
import { VerticalTitle } from "./VerticalTitle";

export const CaptionIndex = () => {
  const title = "Stargazing";

  return (
    <div
      className="captions caption-index"
      // style={{
      //   backgroundImage: `url("/caption-title.png")`,
      //   backgroundSize: "cover",
      // }}
    >
      <NavLink to="/captions/erik-odijk/detail/">
        <VerticalTitle text={title} />
      </NavLink>
    </div>
  );
};
