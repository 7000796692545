import React from "react";
import { VerticalTitle } from "./VerticalTitle";
import { NavLink } from "react-router-dom";
import { WorkProperties } from "./WorkProperties";
import { useQuery } from "react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import { config } from "../config";

const fetchCaption =
  (title: string = "") =>
  async () => {
    const response = await axios.get(
      `${config.API_BASE_URL}api/captions/${title}/`
    );
    return response.data;
  };

export const CaptionDetail = () => {
  const { title } = useParams();
  const { data } = useQuery("caption", fetchCaption(title));

  return (
    data && (
      <div
        className="captions caption-detail"
        // style={{
        //   backgroundImage: `url("/caption-detail.png")`,
        //   backgroundSize: "cover",
        // }}
      >
        <nav>
          <div className="nav-icon"></div>
        </nav>
        <div className="container">
          <h1 className="artist">{data.artist}</h1>
          <div className="work-titles">
            {data.work_properties.map((prop: any) => (
              <WorkProperties {...prop} key={Math.random()} />
            ))}
          </div>
          <article>{data.text}</article>
        </div>
        <NavLink to={`/captions/${title}`}>
          <VerticalTitle text={data.title} />
        </NavLink>
      </div>
    )
  );
};
