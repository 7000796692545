import React from "react";

export const VerticalTitle = (props: { text: string }) => {
  return (
    <div className="vertical-title">
      <h1 aria-label={props.text}>
        {props.text
          .toUpperCase()
          .split("")
          .map((char: string) => (
            <span aria-hidden="true" key={Math.random()}>
              {char}
            </span>
          ))}
      </h1>
    </div>
  );
};
