import React from "react";

import { devices } from "../styling-config";

import styled from "styled-components";

const NavIconContainer = styled.div`
  line-height: 0.9;
  @media only screen and ${devices.xs} {
    svg {
      width: 50px;
      height: 50px;
    }
  }
  @media only screen and ${devices.lg} {
    /* svg {
      width: 100px;
      height: 100px;
    } */
  }
`;

export const NavIcon = () => {
  return (
    <NavIconContainer className="nav-icon">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 170.1 170.1"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`
	.nav-icon .st0{fill:#231F20;}
	.nav-icon .st1{fill:none;}
	.nav-icon .st2{fill:#FFFFFF;}`}
        </style>
        <path
          className="st0"
          d="M150.2,85c0-36-29.2-65.2-65.2-65.2S19.8,49,19.8,85c0,36,29.2,65.2,65.2,65.2S150.2,121,150.2,85"
        />
        <rect className="st1" width="170.1" height="170.1" />
        <path
          className="st2"
          d="M115.5,99.6l-1.4,1.4H56l-1.4-1.4v-4.2L56,94h58.2l1.4,1.4V99.6z M115.5,74.7l-1.4,1.4H56l-1.4-1.4v-4.2
	l1.4-1.4h58.2l1.4,1.4V74.7z"
        />
      </svg>
    </NavIconContainer>
  );
};
