import React from "react";
import { _InlineImage } from "../Article";
import styled from "styled-components";
import { config } from "../../config";
import { devices } from "../../styling-config";
const ImageContainer = styled.div`
  &.limit_width {
    display: block;
    max-width: 700px;
    margin: 0 auto;

    @media only screen and ${devices.xs} {
      width: 85%;
      margin-left: 2.7rem;
    }
    @media only screen and ${devices.lg} {
      max-width: 700px;
      margin: 0 auto;
    }

    .caption {
      margin-left: 0;
      margin-top: -0.1rem;
    }
  }
  .caption {
    margin-bottom: 1rem;
  }
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 1rem;
`;

export const Caption = styled.div`
  font-family: "ReplicaMonoLLWeb-Regular";
  font-size: 0.8rem;
  color: rgb(174, 174, 174);
  margin-left: 2.7rem;
`;

export const InlineImage = (props: _InlineImage) => {
  return (
    <ImageContainer className={props.limit_width ? "limit_width" : ""}>
      <Image src={`${config.API_BASE_URL}${props.image_src}`} alt="" />
      {props.caption && <Caption className="caption">{props.caption}</Caption>}
    </ImageContainer>
  );
};
