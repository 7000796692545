import React, { useEffect, useRef, useState } from "react";
import "./App.css";

import { Route, Routes } from "react-router";
import { LongreadIndex } from "./pages/LongreadIndex";
import { LongreadNav } from "./pages/LongreadNav";
import { Longread } from "./pages/Longread";

import { CaptionDetail } from "./components/CaptionDetail";
import { CaptionIndex } from "./components/CaptionIndex";
import { QueryClient, QueryClientProvider } from "react-query";
import { ScrollToTop } from "./hooks/scrollToTop";
import { LanguageProvider } from "./providers/LanguageProvider";
const queryClient = new QueryClient();

function App() {
  const ref = useRef(1);
  const [range, setRange] = useState(ref.current);

  const handleSetRange = (num: number) => {
    ref.current = num;
    setRange(num);
  };

  return (
    <div className="app">
      <ScrollToTop />
      <LanguageProvider>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<LongreadIndex language="nl" />} />
            <Route path="/nl" element={<LongreadIndex language="nl" />} />
            <Route path="/en" element={<LongreadIndex language="en" />} />
            <Route path="/de" element={<LongreadIndex language="de" />} />
            <Route
              path="/index"
              element={<LongreadNav range={range} setRange={handleSetRange} />}
            />
            <Route
              path="/articles/"
              element={<LongreadNav range={range} setRange={handleSetRange} />}
            />
            <Route path="/articles/:id/" element={<Longread />} />
            <Route path="/captions/:title" element={<CaptionIndex />} />
            <Route path="/captions/:title/detail" element={<CaptionDetail />} />
          </Routes>
        </QueryClientProvider>
      </LanguageProvider>
    </div>
  );
}

export default App;
