import React from "react";
import { styled } from "styled-components";
import { devices } from "../styling-config";

const Logo = styled.div`
  position: fixed;
  top: 1.2rem;
  left: 1.2rem;
  z-index: 10;

  svg {
    width: 33px;
    height: 33px;
  }
`;

export const ValkhofLogo = ({ color = "white" }: { color?: string }) => {
  return (
    <Logo>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 148 122.9"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`
	.st0{fill:${color};}
    `}
        </style>
        <rect y="6.5" className="st0" width="21.3" height="1.4" />
        <rect y="12.9" className="st0" width="21.3" height="1.9" />
        <rect x="126.7" y="12.9" className="st0" width="21.3" height="1.9" />
        <rect x="126.7" y="38.4" className="st0" width="21.3" height="3.9" />
        <rect x="126.7" y="25.6" className="st0" width="21.3" height="2.9" />
        <rect x="126.7" y="6.5" className="st0" width="21.3" height="1.4" />
        <rect x="126.7" y="32" className="st0" width="21.3" height="3.4" />
        <rect x="126.7" y="19.3" className="st0" width="21.3" height="2.4" />
        <rect x="126.7" y="57.5" className="st0" width="21.3" height="65.4" />
        <rect y="57.5" className="st0" width="21.3" height="65.4" />
        <rect x="126.7" y="51.1" className="st0" width="21.3" height="4.9" />
        <rect y="44.8" className="st0" width="21.3" height="4.4" />
        <rect y="38.4" className="st0" width="21.3" height="3.9" />
        <rect y="32" className="st0" width="21.3" height="3.4" />
        <rect y="25.6" className="st0" width="21.3" height="2.9" />
        <rect y="19.3" className="st0" width="21.3" height="2.4" />
        <rect y="51.1" className="st0" width="21.3" height="4.9" />
        <rect x="126.7" y="44.8" className="st0" width="21.3" height="4.4" />
        <polygon
          className="st0"
          points="105.1,0 74.1,120.6 42.9,0 0,0 0,1 21.7,1 53.8,122.9 94.3,122.9 126.3,1 148,1 148,0 "
        />
      </svg>
    </Logo>
  );
};
