import { LanguageContext } from "../providers/LanguageProvider";
import { useContext } from "react";
import { styled } from "styled-components";
import { devices } from "../styling-config";

const Container = styled.div`
  position: fixed;
  left: 2rem;
  bottom: 2rem;
  color: white;
  z-index: 10;
  @media only screen and ${devices.xs} {
    left: 1rem;
    bottom: 2rem;
  }
`;

const Languages = styled.div`
  @media only screen and ${devices.xs} {
    margin-left: 0rem;
  }
  @media only screen and ${devices.md} {
    margin-left: 0rem;
  }
  @media only screen and ${devices.lg} {
    margin-left: 7rem;
  }

  div {
    cursor: pointer;
    color: rgb(174, 174, 174);
    text-align: center;
    display: inline-block;
    margin-right: 3rem;
    &:hover {
      color: white;
    }
    @media only screen and ${devices.xs} {
      margin-right: 2rem;
    }
    @media only screen and ${devices.md} {
      width: auto;
      font-size: 1.4rem;
      margin-right: 3rem;
    }
    @media only screen and ${devices.lg} {
      width: auto;
    }
  }
`;

export const LanguageSwitch = () => {
  const { language, setLanguage } = useContext(LanguageContext);

  return (
    <Container>
      <Languages>
        <div onClick={() => setLanguage("default")}>
          {language === "default" ? "[NL]" : "NL"}
        </div>
        <div onClick={() => setLanguage("en")}>
          {language === "en" ? "[EN]" : "EN"}
        </div>
        <div onClick={() => setLanguage("de")}>
          {language === "de" ? "[DE]" : "DE"}
        </div>
      </Languages>
    </Container>
  );
};
