import React, { useContext } from "react";
import { LanguageContext } from "../providers/LanguageProvider";
import { styled } from "styled-components";
import { devices } from "../styling-config";

const Container = styled.div`
  position: fixed;
  top: 1.2rem;
  left: 10rem;
  right: 0;

  h2 {
    font-size: 1.4rem;
    font-weight: normal;
    color: rgb(174, 174, 174);
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    letter-spacing: 0.05rem;
    padding: 0;
  }

  @media only screen and ${devices.xs} {
    display: none;
  }
  @media only screen and ${devices.lg} {
    display: block;
  }
`;

const publicationDictionary: { [key: string]: string } = {
  default: "[Publicatie]",
  en: "[Publication]",
  de: "[Publikation]",
};

export const PublicationTag = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Container>
      <h2>{publicationDictionary[language]}</h2>
    </Container>
  );
};
