import React from "react";

export const Logo = (props: any) => {
  return (
    <div className={`logo-layer ${props.direction}`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="1080"
        height="1080"
        viewBox="0 0 1080 1080"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`
	        .logo-layer.${props.direction} .line {fill:${props.textColor}}
            `}
        </style>
        {/* <g id="cirkel" className="st1">
          <circle className="st2" cx="540.7" cy="539.8" r="33.4" />
        </g> */}
        <g>
          <g>
            <path
              className="line"
              d="M467.3,840.3c16.2,3.9,26.9-2.7,30.6-12.4l-0.9-1.5L487,824l-1.5,0.9c-4,5.6-9.2,5.7-15.5,4.2
				c-16.2-3.9-14.4-16.6-13.8-19.1l1.5-0.9l43.5,10.4l1.5-0.9l0.9-3.7c4.2-17.4-1-34.4-22.1-39.5s-33.4,7.8-37.6,25.2
				C439.5,819.2,446.1,835.3,467.3,840.3 M489.9,806.3l-29.8-7.1l-0.9-1.5c0.9-3.7,4.8-14.6,19.7-11c14.9,3.6,13.5,15.1,12.6,18.8
				L489.9,806.3z M423.1,827.1l2.1-8.7l-0.9-1.5l-18.6-4.5l-0.9-1.5l17.6-73.3l-0.9-1.5l-29.8-7.1l-1.5,0.9l-2.1,8.7l0.9,1.5
				l17.4,4.2l0.9,1.5l-14.9,62.2l-1.5,0.9l-23.6-5.7l-1.5,0.9l-2.1,8.7l0.9,1.5l57.2,13.7L423.1,827.1z M317.1,792.5
				c-11.2-2.7-16.9-11.9-13.6-25.6c3.3-13.7,12.5-19.3,23.7-16.7l1.2,0.3c11.2,2.7,16.9,11.9,13.6,25.6
				c-3.3,13.7-12.5,19.3-23.7,16.7L317.1,792.5z M315.6,804c18.6,4.5,34.1-5,38.8-24.9c4.8-19.9-4.7-35.3-23.3-39.8l-1.2-0.3
				c-18.6-4.5-34.1,5-38.8,24.9c-4.8,19.9,4.7,35.3,23.3,39.8L315.6,804z M269,790.2l20.3-84.5l-0.9-1.5l-9.9-2.4l-1.5,0.9
				l-8.3,34.8l-1.5,0.9l-31.1-7.4l-0.9-1.5l8.3-34.8l-0.9-1.5l-9.9-2.4l-1.5,0.9l-20.3,84.5l0.9,1.5l9.9,2.4l1.5-0.9l8.6-36l1.5-0.9
				l31.1,7.4l0.9,1.5l-8.6,36l0.9,1.5l9.9,2.4L269,790.2z"
            />
            <path
              className="line"
              d="M536.2,673.2l2.1-8.7l-13-28.1l0.3-1.2l21.6-13.2l2.1-8.7l-0.9-1.5l-3.7-0.9l-2.8,0.6l-38.5,23.6l-1.2-0.3
				l13.4-55.9l-0.9-1.5l-9.9-2.4l-1.5,0.9l-20.3,84.5l0.9,1.5l9.9,2.4l1.5-0.9l2.4-9.9l1.8-2.2l13.9-8.5l1.2,0.3l13,28.1l2.2,1.8
				l5,1.2L536.2,673.2z M466.4,635.4l-0.9-1.5l-9.9-2.4l-1.5,0.9c-3,7.2-8.9,9.7-17.6,7.6c-12.4-3-16.9-11.9-13.6-25.6
				c3.3-13.7,11.3-19.6,23.7-16.7c8.7,2.1,12.8,7,12.2,14.8l0.9,1.5l9.9,2.4l1.5-0.9c2.6-16.5-9.6-25.9-22-28.9
				c-19.9-4.8-34.1,5-38.8,24.9c-4.8,19.9,3.4,35,23.3,39.8C446.2,654.2,461.3,651.3,466.4,635.4 M376,612.4
				c-1.5,6.2-10.4,10.6-20.4,8.3c-7.5-1.8-12.1-4.2-10.3-11.7c1.8-7.5,8.6-8.5,19.8-5.8c7.5,1.8,10.9,3.9,10.9,3.9l0.9,1.5
				L376,612.4z M383.4,636.5l8.3-34.8c4.5-18.6-0.9-29.1-19.6-33.6c-18.6-4.5-28.2,2.4-30.6,12.4l0.9,1.5l9.9,2.4l1.5-0.9
				c1.2-5,6.8-6.3,15.5-4.2s13.1,5.8,11,14.5l-0.6,2.5l-1.5,0.9c0,0-4.7-2.4-12.1-4.2c-16.2-3.9-29.8-1.9-33.4,13
				c-3.3,13.7,3.9,22,17.6,25.2c13.7,3.3,20.4-3,20.4-3l1.2,0.3l0.1,5.3l0.9,1.5l8.7,2.1L383.4,636.5z M312.5,619.6l2.1-8.7
				l-0.9-1.5l-18.6-4.5l-0.9-1.5l17.6-73.3l-0.9-1.5l-29.8-7.1l-1.5,0.9l-2.1,8.7l0.9,1.5l17.4,4.2l0.9,1.5l-14.9,62.2l-1.5,0.9
				l-23.6-5.7l-1.5,0.9l-2.1,8.7l0.9,1.5l57.2,13.7L312.5,619.6z M231.2,575.1c-2.4,9.9-8.2,12.5-18.2,10.1l-21.1-5.1l-0.9-1.5
				l6.3-26.1l1.5-0.9l18.6,4.5C229.8,559,233.8,563.9,231.2,575.1 M237.9,536c-2.4,9.9-8,11.2-17.9,8.9l-18.6-4.5l-0.9-1.5l5.4-22.4
				l1.5-0.9l17.4,4.2C235.9,522.4,240.3,526,237.9,536 M243.6,578.1c2.7-11.2-2-18.9-8.9-23.2l0.3-1.2c6.8-1,13.3-6,15.4-14.7
				c4.2-17.4-6.8-26.6-20.4-29.9l-32.3-7.7l-1.5,0.9l-20.3,84.5l0.9,1.5l34.8,8.3C225.2,600,239.1,596.7,243.6,578.1"
            />
            <path
              className="line"
              d="M397.7,461.6c16.2,3.9,26.9-2.7,30.6-12.4l-0.9-1.5l-9.9-2.4l-1.5,0.9c-4,5.6-9.2,5.7-15.5,4.2
				c-16.2-3.9-14.4-16.6-13.8-19.1l1.5-0.9l43.5,10.4l1.5-0.9l0.9-3.7c4.2-17.4-1-34.4-22.1-39.5c-21.1-5.1-33.4,7.8-37.6,25.2
				C369.9,440.4,376.6,456.5,397.7,461.6 M420.3,427.5l-29.8-7.1l-0.9-1.5c0.9-3.7,4.8-14.6,19.7-11c14.9,3.6,13.5,15.1,12.6,18.8
				L420.3,427.5z M349.8,447.5l8.6-36c3.3-13.7,0-27.6-18.6-32.1c-12.4-3-19.2,3.3-19.2,3.3l-1.2-0.3l7.1-29.8l-0.9-1.5l-9.9-2.4
				l-1.5,0.9l-20.3,84.5l0.9,1.5l9.9,2.4l1.5-0.9l7.4-31.1c3.6-14.9,12.2-18.1,22.2-15.7c9.9,2.4,11.9,10.7,10.1,18.2l-8.6,36
				l0.9,1.5l9.9,2.4L349.8,447.5z M233.6,394.7c-4.5,18.6,4.9,28.8,18.6,32.1c9.9,2.4,24.8,0.7,29-16.7l0.6-2.5l-0.9-1.5l-9.9-2.4
				l-1.5,0.9l-0.6,2.5c-2.1,8.7-8.9,9.7-13.9,8.5c-6.2-1.5-11.8-5.5-8.9-17.9l6-24.9l1.5-0.9l21.1,5.1l1.5-0.9l2.1-8.7l-0.9-1.5
				l-21.1-5.1l-0.9-1.5l3.9-16.2l-0.9-1.5l-9.9-2.4l-1.5,0.9l-3.9,16.2l-1.5,0.9l-9.9-2.4l-1.5,0.9l-2.1,8.7l0.9,1.5l9.9,2.4
				l0.9,1.5L233.6,394.7z"
            />
            <path
              className="line"
              d="M591.8,315.2c-11.2-2.7-16.9-11.9-13.6-25.6c3.3-13.7,12.5-19.3,23.7-16.7l1.2,0.3
				c11.2,2.7,16.9,11.9,13.6,25.6s-12.5,19.3-23.7,16.7L591.8,315.2z M590.4,326.7c18.6,4.5,34.1-5,38.8-24.9s-4.7-35.3-23.3-39.8
				l-1.2-0.3c-18.6-4.5-34.1,5-38.8,24.9c-4.8,19.9,4.7,35.3,23.3,39.8L590.4,326.7z M502.1,277.9c-4.5,18.6,4.9,28.8,18.6,32.1
				c9.9,2.4,24.8,0.7,29-16.7l0.6-2.5l-0.9-1.5l-9.9-2.4l-1.5,0.9l-0.6,2.5c-2.1,8.7-8.9,9.7-13.9,8.5c-6.2-1.5-11.8-5.5-8.9-17.9
				l6-24.9l1.5-0.9l21.1,5.1l1.5-0.9l2.1-8.7l-0.9-1.5l-21.1-5.1l-0.9-1.5l3.9-16.2l-0.9-1.5l-9.9-2.4l-1.5,0.9l-3.9,16.2l-1.5,0.9
				l-9.9-2.4l-1.5,0.9l-2.1,8.7l0.9,1.5l9.9,2.4l0.9,1.5L502.1,277.9z M466.7,294.4l8.6-36c3.6-14.9-1.2-27.9-18.6-32.1
				c-12.4-3-19.2,3.3-19.2,3.3l-1.2-0.3l-0.1-5.3l-0.9-1.5l-8.7-2.1l-1.5,0.9L410.7,281l0.9,1.5l9.9,2.4l1.5-0.9l7.1-29.8
				c3.6-14.9,11.3-19.6,22.5-17c11.2,2.7,11.6,12,10.1,18.2l-8.6,36l0.9,1.5l9.9,2.4L466.7,294.4z M388.3,275.6l2.1-8.7l-0.9-1.5
				l-17.4-4.2l-0.9-1.5l14.9-62.2l1.5-0.9l16.2,3.9l1.5-0.9l2.1-8.7l-0.9-1.5L359.2,178l-1.5,0.9l-2.1,8.7l0.9,1.5l16.2,3.9l0.9,1.5
				l-14.9,62.2l-1.5,0.9l-17.4-4.2l-1.5,0.9l-2.1,8.7l0.9,1.5l49.7,11.9L388.3,275.6z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
