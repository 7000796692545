import React from "react";
import { Logo } from "./Logo";

export const AnimatedLogo = () => {
  return (
    <div className="logo-container">
      <Logo id="upper" textColor={"white"} direction="forward" />
      <Logo id="lower" textColor={`rgb(10,14,22);`} direction="backward" />
    </div>
  );
};
