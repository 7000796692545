import React, { useEffect } from "react";

import { AnimatedLogo } from "../components/AnimatedLogo";
import { AnimatedBackground } from "../components/AnimatedBackground";
import { NavIcon } from "../components/NavIcon";
import { MuseumTitle } from "../components/MuseumTitle";
import { ValkhofLogo } from "../components/ValkhofLogo";
import { NavLink } from "react-router-dom";
import { PublicationTitle } from "../components/PublicationTitle";
import { styled } from "styled-components";

import { devices } from "../styling-config";

const PattyLink = styled.div`
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and ${devices.xs} {
    top: calc(50vh - 2.5rem);
    right: calc(50vw - 2.5rem);
    width: 5rem;
    height: 5rem;
  }
  @media only screen and ${devices.lg} {
    top: calc(50vh - 1.25rem);
    right: calc(50vw - 1.25rem);
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const languages = ["nl", "en", "de"];

export const LongreadIndex = ({ language }: { language: string }) => {
  useEffect(() => {
    if (languages.includes(language)) {
      localStorage.setItem("language", language);
    }
  }, [language]);

  return (
    <div className="longread">
      <div className="longread-index">
        <a href="https://valkhofmuseum.nl/">
          <ValkhofLogo color="white" />
        </a>
        <PublicationTitle />
        <AnimatedLogo />
        <PattyLink>
          <NavLink to="/index" className="patty-link">
            <NavIcon />
          </NavLink>
        </PattyLink>

        <MuseumTitle />
        <AnimatedBackground />
      </div>
    </div>
  );
};
