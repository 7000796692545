import { styled } from "styled-components";
import { ConditionalNavLink } from "../ConditionalNavLink";
import { useViewport } from "../../hooks/useViewport";
import { NavImageBlock } from "../NavImageBlock";
import { NavTextBlock } from "../NavTextBlock";
import { NavQuestionBlock } from "../NavQuestionBlock";
import { IconClose } from "../IconClose";
import { LanguageSwitch } from "../LanguageSwitch";
import { NavLink } from "react-router-dom";
import { CarouselControls } from "./CarouselControls";
import { CarouselItem } from "./CarouselItem";
import { Ref, RefObject, useEffect } from "react";

const StyledCarousel = styled.div`
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  width: 100vw;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  padding-bottom: 4rem;
`;

export const Carousel = ({
  items,
  carouselRef,
  refsById,
  range,
  handleRangeChange,
}: {
  items: any;
  carouselRef: any;
  refsById: { [key: number]: any };
  range: any;
  handleRangeChange: any;
}) => {
  const { width } = useViewport();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          handleRangeChange(entry.target.id, false);
        }
      },
      { threshold: 1 }
    );
    for (const [, ref] of Object.entries(refsById)) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [refsById]);

  return (
    <>
      <StyledCarousel
        className="article-nav invisible-scrollbar"
        ref={carouselRef}
      >
        {items.map((item: any, idx: number) => {
          return (
            <CarouselItem
              id={`${idx}`}
              className={`carousel-item ${item.type} ${
                width > 768 ? "large" : ""
              } `}
              style={{
                width: width > 768 ? "95vh" : "250px",
                height: width > 768 ? "65vh" : "60vh",
              }}
              ref={refsById[idx]}
              key={idx}
            >
              <ConditionalNavLink
                to={`/articles/${item.name}/`}
                show={item.type === "article"}
              >
                <div
                  className={`container ${item.text ? "text" : ""} ${
                    item.image_src ? "image" : ""
                  }
                  ${item.type === "question" ? "question" : ""}`}
                >
                  {item.image_src && <NavImageBlock item={item} />}
                  {item.text && <NavTextBlock item={item} />}
                  {item.type === "question" && <NavQuestionBlock item={item} />}
                </div>
              </ConditionalNavLink>
            </CarouselItem>
          );
        })}
      </StyledCarousel>

      <CarouselControls
        range={range}
        handleChange={handleRangeChange}
        max={items.length}
      />

      <NavLink to="/">
        <IconClose color="rgba(205,205,205,.5)" />
      </NavLink>
    </>
  );
};
