import { devices } from "../../styling-config";
import { styled } from "styled-components";

const ControlsContainer = styled.div`
  position: fixed;
  left: calc(50vw - 500px / 2);
  bottom: 10rem;
  z-index: 10;

  input {
    width: 100%;
    background-color: transparent;
    color: black;
    -webkit-appearance: none;
  }

  input[type="range"]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  @media only screen and ${devices.xs} {
    left: calc(50vw - (70vw / 2));
    width: 70vw;
    bottom: 5rem;
  }
  @media only screen and ${devices.lg} {
    left: calc(50vw - (500px / 2));
    width: 500px;
  }
`;

export const CarouselControls = ({
  range,
  handleChange,
  max,
}: {
  range: any;
  handleChange: any;
  max: number;
}) => {
  return (
    <ControlsContainer>
      <input
        type="range"
        value={range}
        onChange={(e) => {
          handleChange(e.currentTarget.valueAsNumber);
        }}
        min="0"
        max={max - 1}
        step="1"
      />
    </ControlsContainer>
  );
};
