import { styled } from "styled-components";
import { devices } from "../../styling-config";

export const CarouselItem = styled.div`
  scroll-snap-align: center;
  overflow: hidden;

  &:first-child {
    margin-left: 50vw;
  }
  &:last-child {
    margin-right: 50vw;
  }

  a {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
  }

  .container {
    width: 100%;
    height: 100%;
  }
  max-width: 60vw;
  @media only screen and ${devices.xs} {
    width: 250px;
    height: 60vh;
  }
  @media only screen and ${devices.lg} {
    width: 95vh;
    height: 65vh;
  }
`;
