import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { config } from "../config";
import { Carousel } from "./Carousel/Carousel";
import { IconClose } from "./IconClose";
import { NavLink } from "react-router-dom";
import { LanguageSwitch } from "./LanguageSwitch";

const fetchArticles = async () => {
  const response = await axios.get(`${config.API_BASE_URL}api/articles/`, {
    headers: {
      "Accept-Language": window.localStorage.getItem("language") || "default",
    },
  });
  return response.data;
};

type Article = {
  id: string;
  text: string;
  image_src?: string;
  title: string;
  name: string;
  author: string;
  year: string;
  type: string;
  subtitle?: string;
  bold_subtitle?: string;
};

export const ArticleNavContainer = ({
  startRange,
  range,
  setRange,
}: {
  startRange: number;
  range: any;
  setRange: any;
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  // on first render
  useEffect(() => {
    handleRangeChange(startRange, true);
    // setHasStarted(true);
  }, []);

  const handleRangeChange = (num: any, scroll = true) => {
    setRange(num);
    if (scroll && refsById[num]) {
      refsById[num].current.scrollIntoView();
    }
  };

  const { data } = useQuery("articles", fetchArticles);
  const items: Article[] = data && data.articles;
  const questions: Article[] = data && data.questions;
  const questionInsertionPoints = [1, 3, 7, 10, 12]; // five buckets

  const shuffle = (array: any[]) => {
    array.sort(() => Math.random() - 0.5);
  };

  const createQuestionBuckets = (
    amountOfBuckets: number,
    questions: Article[]
  ) => {
    const questionsPerBucket = Math.ceil(questions.length / amountOfBuckets);
    const buckets = [...Array(amountOfBuckets)].map((bucket): Article[] => []);
    shuffle(questions);

    let bucketIndex = 0;
    // distribute questions randomly over buckets.
    questions.forEach((q, idx) => {
      if (idx > 0 && idx % questionsPerBucket === 0) {
        bucketIndex++;
      }
      buckets[bucketIndex].push(questions[idx]);
    });

    return buckets;
  };

  const questionBuckets = questions && createQuestionBuckets(5, questions);

  const getRandomQuestion = (buckets: Article[][], bucketNumber: number) => {
    return buckets[bucketNumber][0];
  };

  let bucketIdx = 0;
  const itemsWithQuestions =
    items &&
    items.reduce((acc: Article[], cur: Article, idx: number) => {
      acc.push(cur);
      if (questionInsertionPoints.includes(idx)) {
        acc.push(getRandomQuestion(questionBuckets, bucketIdx));
        if (bucketIdx < questionBuckets.length) {
          bucketIdx++;
        } else {
          bucketIdx = 0;
        }
      }
      return acc;
    }, []);

  const refsById = useMemo(() => {
    const refs: { [key: number]: any } = {};
    if (itemsWithQuestions) {
      itemsWithQuestions.forEach((_, idx) => {
        refs[idx] = createRef();
      });
    }
    return refs;
  }, [itemsWithQuestions]);

  return (
    data && (
      <>
        <Carousel
          items={itemsWithQuestions}
          carouselRef={carouselRef}
          refsById={refsById}
          range={range}
          handleRangeChange={handleRangeChange}
        />
        <NavLink to="/">
          <IconClose color="rgba(205,205,205,.5)" />
        </NavLink>
        <LanguageSwitch />
      </>
    )
  );
};
