import React from "react";
import { styled } from "styled-components";
import { devices } from "../styling-config";

const Close = styled.div`
  position: fixed;
  top: 1.2rem;
  right: 1.2rem;
  width: 35px;
  height: 35px;
  z-index: 10;

  @media only screen and ${devices.xs} {
    top: 1.2rem;
    right: 1.2rem;
  }
  @media only screen and ${devices.lg} {
    top: 1.2rem;
    right: 1.2rem;
  }
`;

export const IconClose = ({ color = "white" }: { color?: string }) => {
  const [opacity, setOpacity] = React.useState(0.8);
  const handleHover = (opacity: number) => (e: any) => {
    setOpacity(opacity);
  };
  return (
    <Close>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 130.3999939 130.3999939"
        xmlSpace="preserve"
        onMouseOver={handleHover(1)}
        onMouseOut={handleHover(0.8)}
      >
        <path
          style={{ fill: color, opacity }}
          d="
			M65.1999969,130.3999939c36,0,65.1999969-29.1999969,65.1999969-65.1999969S101.1999969,0,65.1999969,0S0,29.2000008,0,65.1999969
			S29.2000008,130.3999939,65.1999969,130.3999939"
        />

        <g>
          <line
            stroke="black"
            strokeWidth="6"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="42.0315742"
            y1="42.5733643"
            x2="88.3684158"
            y2="88.9102097"
          />
          <line
            stroke="black"
            strokeWidth="6"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="42.0315742"
            y1="88.9102097"
            x2="88.3684158"
            y2="42.5733643"
          />
        </g>
      </svg>
    </Close>
  );
};
