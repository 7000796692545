import React from "react";
import { _Logos } from "../Article";
import { Link, NavLink } from "react-router-dom";
import { PublicationTitle } from "../PublicationTitle";
import { config } from "../../config";
import { styled } from "styled-components";
import { devices } from "../../styling-config";

const BlockLogos = styled.div`
  position: relative;
  margin-left: 2.7rem;
  margin-right: 1.2rem;
  margin: 0 auto;
  @media only screen and ${devices.xs} {
    margin-left: 2rem;
    max-width: 700px;
    img {
      width: 100%;
    }
  }
  @media only screen and ${devices.lg} {
    margin: 0 auto;
    max-width: 700px;
    img {
      width: 50%;
    }
  }

  a {
    display: block;
    width: 50%;
    box-sizing: border-box;
    padding: 0.25rem;
  }
`;

export const Logos = (props: _Logos) => {
  return (
    <BlockLogos>
      {props.logoImages.map((logo: any) => (
        <Link to={logo.hyperlink}>
          <img src={`${config.API_BASE_URL}${logo.image_src}`} alt={logo.alt} />
        </Link>
      ))}
    </BlockLogos>
  );
};
