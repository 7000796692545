import React from "react";

export const MuseumTitle = () => {
  return (
    <div className="index-title">
      <h2>
        Valkhof Museum
        <br />
        Radboud Universiteit
      </h2>
    </div>
  );
};
