import React from "react";
import { _OpeningImage } from "../Article";
import { NavLink } from "react-router-dom";
import { PublicationTitle } from "../PublicationTitle";
import { config } from "../../config";
import { styled } from "styled-components";
import { devices } from "../../styling-config";

const ArticleSubTitle = styled.div<{ blackText?: number }>`
  color: ${(props) => (props?.blackText ? "black" : "white")};
  font-size: 1.7rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 0;
  white-space: pre-wrap;
`;

const BlockOpeningImage = styled.div`
  position: relative;
  z-index: 5;
`;

const Content = styled.div<{ blackText?: number }>`
  margin-left: 2.7rem;
  margin-right: 2.7rem;
  padding-top: 3.5dvh;
  @media only screen and ${devices.lg} {
    margin: 0 auto;
    max-width: 700px;
  }
  @media only screen and ${devices.lg} {
    margin: 0 auto;
    max-width: 700px;
  }
  color: ${(props) => (props?.blackText ? "black" : "white")};
`;

const Author = styled.div<{ blackText?: number }>`
  color: ${(props) => (props?.blackText ? "black" : "white")};
`;

const Caption = styled.div`
  font-family: "ReplicaMonoLLWeb-Regular";
  font-size: 0.8rem;
  color: rgb(174, 174, 174);
  @media only screen and ${devices.xs} {
    margin-top: 0.2rem;
    margin-left: 2.7rem;
  }
  @media only screen and ${devices.lg} {
    margin: 0 auto;
    margin-top: 0.2rem;
    max-width: 700px;
  }
`;
export const OpeningImage = (props: _OpeningImage) => {
  return (
    <>
      <BlockOpeningImage className="block-opening-image">
        <div
          className="introduction"
          style={{
            backgroundImage: `url("${config.API_BASE_URL}${props.image_src}")`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            boxSizing: "border-box",
          }}
        >
          <Content blackText={props.black_text}>
            <div className="heading">
              <NavLink to="/index">
                <PublicationTitle />
              </NavLink>
            </div>

            <h1>{props.title}</h1>
            <ArticleSubTitle blackText={props.black_text}>
              {props.subtitle}
            </ArticleSubTitle>
            <Author blackText={props.black_text} className="author">
              {props.author}
            </Author>
          </Content>
        </div>
      </BlockOpeningImage>
      {props.caption && <Caption>{props.caption}</Caption>}
    </>
  );
};
