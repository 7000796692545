import React from "react";

import { devices } from "../styling-config";

import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  /* margin: 0 auto;
  max-width: 700px; */
  h2,
  h3 {
    font-weight: normal;
  }

  @media only screen and ${devices.xs} {
    top: 1.2rem;
    left: 6.2rem;
  }
  @media only screen and ${devices.lg} {
    top: 1.2rem;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    z-index: 1;

    h3 {
      display: none;
    }
    h2,
    h3 {
      font-size: 1.4rem;
    }
    h2 {
      /* margin-right: 12.8rem; */
    }
  }
`;

export const PublicationTitle = ({ color = "white" }: { color?: string }) => {
  return (
    <Container className="publication-title" style={{ color }}>
      <h2>INTO THE BLACK HOLE</h2>
      <h3>[Publicatie]</h3>
    </Container>
  );
};
