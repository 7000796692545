import React from "react";
import { NavLink } from "react-router-dom";
import { PropsWithChildren } from "react";

type Props = {
  show: boolean;
  to: string;
};

export const ConditionalNavLink = ({
  show,
  to,
  children,
}: PropsWithChildren<Props>) => {
  if (show) {
    return <NavLink to={to}>{children}</NavLink>;
  } else {
    return <>{children}</>;
  }
};
