import { ReactNode, createContext, useState, useEffect } from "react";
import { focusManager } from "react-query";

type LanguageContextI = {
  language: string;
  setLanguage: (language: string) => void;
};

export const LanguageContext = createContext<LanguageContextI>({
  language: "default",
  setLanguage: () => {},
});

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState("default");

  const languages = ["default", "en", "de"];
  const setLSLanguage = async (newLanguage: string) => {
    if (newLanguage !== language) {
      setLanguage(newLanguage);
      window.localStorage.setItem("language", newLanguage);
      // trigger refetch of data with react query
      focusManager.setFocused(false);
      focusManager.setFocused(true);
    }
  };

  const storedLanguage = window.localStorage.getItem("language");

  useEffect(() => {
    // set language based on localstorage
    if (storedLanguage && languages.includes(storedLanguage)) {
      setLanguage(storedLanguage);
    } else {
      setLanguage("default");
    }
  }, [setLanguage]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage: setLSLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
